import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Helmet } from 'react-helmet'
import 'sanitize.css'
import styled, { createGlobalStyle } from 'styled-components'
import Card from '../components/Card'
import Social from '../components/Social'

const GlobalStyle = createGlobalStyle`
  html {
    -webkit-font-smoothing: antialiased;
  }
`

const Main = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Raleway', sans-serif;
  color: #3b3b3b;
`

const Name = styled.h1`
  font-size: 1.6rem;
  margin: 0;
`

const Description = styled.h2`
  margin: 0 0 0rem;
  font-size: 1rem;
`

const ProfileImage = styled.img`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center right;
  position: absolute;
  right: -20px;
  top: -20px;
`

const HeroImageBox = styled.div`
  z-index: -1;
  background: black;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

export default ({ data }) => {
  return (
    <div>
      <GlobalStyle />

      <Helmet>
        <title>Christer Persson / Senior web developer</title>
        <link
          href="https://fonts.googleapis.com/css?family=Raleway&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <HeroImageBox>
        <Img
          fluid={[
            data.mobileImage.childImageSharp.fluid,
            {
              ...data.desktopImage.childImageSharp.fluid,
              media: `(min-width: 600px)`,
            },
          ]}
          alt="hero"
          style={{ height: '100vh' }}
        />
      </HeroImageBox>

      <Main>
        <Card>
          {/* <ProfileImage src={profile} alt="Profile" /> */}

          <Name>Christer Persson</Name>

          <Description>Senior web developer & software engineer</Description>

          <Social style={{ padding: '1.5rem 0 0' }} />
        </Card>
      </Main>
    </div>
  )
}

export const fluidMobileFragment = graphql`
  fragment fluidMobileFragment on File {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const fluidDesktopFragment = graphql`
  fragment fluidDesktopFragment on File {
    childImageSharp {
      fluid(maxWidth: 2000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    mobileImage: file(relativePath: { eq: "hero-mobile.jpg" }) {
      ...fluidMobileFragment
    }
    desktopImage: file(relativePath: { eq: "hero-desktop.jpg" }) {
      ...fluidDesktopFragment
    }
  }
`
