import React from 'react'
import styled from 'styled-components'

const Box = styled.div`
  position: relative;
  background: #fff;
  border-radius: 0.75rem;
  padding: 1rem 1.5rem;
  margin: 1rem;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);

  @media (min-width: 500px) {
    padding: 1.9rem 2.4rem;
  }
`

const Card = ({ children }) => <Box>{children}</Box>

export default Card
