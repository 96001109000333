import React from 'react'
import styled from 'styled-components'

const Box = styled.div`
  fill: inherit;

  & a:not(:first-child) {
    margin-left: 0.25rem;
  }
`

const HoverableSVG = styled.svg`
  transition: fill 0.15s ease-in;

  && {
    fill: inherit;
  }

  & :hover {
    fill: #ee4d4d;
  }
`

const LinkedInIcon = props => (
  <HoverableSVG height="50px" viewBox="0 0 50 50" width="50px" {...props}>
    <g fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g id="LinkedIn">
        <path
          d="M25,50 C38.8071194,50 50,38.8071194 50,25 C50,11.1928806 38.8071194,0 25,0 C11.1928806,0 0,11.1928806 0,25 C0,38.8071194 11.1928806,50 25,50 Z M25,47 C37.1502651,47 47,37.1502651 47,25 C47,12.8497349 37.1502651,3 25,3 C12.8497349,3 3,12.8497349 3,25 C3,37.1502651 12.8497349,47 25,47 Z M14,20.1180479 L14,34.6581834 L18.7100851,34.6581834 L18.7100851,20.1180479 L14,20.1180479 Z M16.6646962,13 C15.0534058,13 14,14.0858611 14,15.5115122 C14,16.9076331 15.0222711,18.0247614 16.6035556,18.0247614 L16.6336556,18.0247614 C18.2759867,18.0247614 19.2988222,16.9076331 19.2988222,15.5115122 C19.2682519,14.0858611 18.2759867,13 16.6646962,13 Z M30.5769213,20.1180479 C28.076176,20.1180479 26.9565501,21.5293199 26.3314108,22.5193527 L26.3314108,20.4598644 L21.6207614,20.4598644 C21.6828427,21.8242356 21.6207614,35 21.6207614,35 L26.3314108,35 L26.3314108,26.8795887 C26.3314108,26.445032 26.3619812,26.0115368 26.4865199,25.7004084 C26.826932,24.83226 27.6020069,23.9334233 28.9032674,23.9334233 C30.6083381,23.9334233 31.2899149,25.2667202 31.2899149,27.2206333 L31.2899149,34.999614 L35.9998119,34.999614 L36,26.6627446 C36,22.1966439 33.6763743,20.1180479 30.5769213,20.1180479 Z M30.5769213,20.1180479"
          id="Oval-1"
        />
      </g>
    </g>
  </HoverableSVG>
)

const EmailIcon = props => (
  <HoverableSVG height="50" viewBox="0 0 50 50" width="50" {...props}>
    <path
      fillRule="evenodd"
      d="M25 0C11.193 0 0 11.193 0 25s11.193 25 25 25 25-11.193 25-25S38.807 0 25 0zm0 3C12.85 3 3 12.85 3 25s9.85 22 22 22 22-9.85 22-22S37.15 3 25 3zm12 28.965V17l-8.737 8.464 8.737 6.5zm-24 0l8.737-6.502L13 17v14.965zm9.914-5.36L13 33h24l-9.914-6.395L25 28.625l-2.086-2.02zM13.72 17L25 26.058 36.28 17H13.72zm0 0"
    />
  </HoverableSVG>
)

const Social = props => {
  return (
    <Box {...props}>
      <a href="https://www.linkedin.com/in/christerpersson/">
        <LinkedInIcon width="30px" height="30px" />
      </a>
      <a href="mailto:christer.persson@gmail.com">
        <EmailIcon width="30px" height="30px" />
      </a>
    </Box>
  )
}

export default Social
